html,
body {
  height: 100%;
  margin: 0;
  font-family: "League Spartan";
  color: white;
  scroll-behavior: smooth;
}

.app-container {
  display: flex;
  flex-direction: column;
  background: black;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
