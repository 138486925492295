.contact-container {
  width: 100%;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  padding-bottom: 4rem;
}

.contact-card {
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  text-align: center;
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  width: 40%;
  z-index: 2;
  position: relative;
  min-width: 300px;
  border: 0.5px solid white;
}

.contact-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: start;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-label {
  font-size: 1rem;
  font-weight: medium;
}

.form-input,
.form-textarea {
  padding: 0.75rem;
  margin-top: 0.5rem;
  background-color: #2d2d2d;
  color: white;
  border: 1px solid #3a3a3a;
  border-radius: 5px;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  border-color: #51a2e9;
  box-shadow: 0 0 10px rgba(81, 162, 233, 0.5);
}

.contact-form-button {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1rem;
  font-weight: bold;
}

.contact-form-button:hover {
  background-color: #ccc;
  color: black;
}

.contact-form-button:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(81, 162, 233, 0.5);
}