.projects-container {
  width: 100%;
  padding: 20px;
  position: relative;
}

.projects-title {
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: left;
  margin: 0;
}

.projects-title strong {
  color: #00FFFF;
  font-size: 2rem;
  font-weight: bold;
}

.swiper-container {
  padding-top: 60px;
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background: #1a1a1a;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 500px;
  margin: 35px;
  cursor: pointer;
  background: linear-gradient(145deg, black 10%, #49047a 50%, #000000 90%);
}

.project-card:hover {
  transform: scale(1.04);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.project-bg {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.project-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.project-info {
  color: white;
}

.project-name {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 10px;
}

.project-description {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 10px;
}

.project-technologies {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.technology-badge {
  background: #333;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.technology-badge svg {
  margin-right: 5px;
}

.technology-badge:hover {
  opacity: 0.9;
}

.swiper-pagination {
  margin-top: 20px;
  bottom: 0px !important;
}

/* Custom Navigation Buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #00FFFF;
  position: absolute;
  top: 65%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for height */
  width: 44px; /* Increase size */
  height: 44px; /* Increase size */
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next {
  right: 10px; /* Position to the right */
}

.swiper-button-prev {
  left: 10px; /* Position to the left */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
}

.swiper-pagination-bullet {
  background: #00FFFF;
}

.swiper-pagination-bullet-active {
  background: #FF00FF;
}

@media (max-width: 768px) {
  .project-card {
    padding: 15px;
  }

  .project-name {
    font-size: 1.3rem;
  }

  .project-description {
    font-size: 1rem;
  }

  .technology-badge {
    font-size: 0.85rem;
    padding: 4px 8px;
  }
}

@media (max-width: 640px) {
  .project-card {
    padding: 10px;
  }

  .project-name {
    font-size: 1.2rem;
  }

  .project-description {
    font-size: 0.9rem;
  }

  .technology-badge {
    font-size: 0.72rem;
    padding: 3px 6px;
  }
}
