.simple-footer {
    /* background-color: rgb(249, 250, 251); */
    padding: 10px 10px;
    text-align: center;
    font-family: "League Spartan", sans-serif;
    color: #333;
    font-size: 0.875rem;
    margin-top: auto;
  }
  
  .blog-footer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  .footer-links {
    display: flex;
    gap: 20px;
  }
  
  .footer-links a {
    color: #6706ce;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: #333;
  }
  