.about-container {
  padding: 0px 40px;
  margin-top: 20px; 
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.title {
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #00FFFF;
  align-self: center;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.about-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
}

.about-title {
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 60px;
  font-weight: bold;
  color: #00FFFF;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.form-button {
  position: absolute;
  right: 0;
  max-height: 50px;
  transition: transform 0.3s ease;
  background-color: #fff!important;
  color: black !important;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
}

.form-button:hover {
  background-color: #ccc;
  color: black;
}

.form-button:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(81, 162, 233, 0.5);
}

.button-text {
  display: inline-block;
}

.button-icon {
  display: none;
}

.avatar {
  position: absolute;
  left: 0;
  width: 120px; 
  height: 120px;
  border-radius: 50%; 
  overflow: hidden;
  object-fit: cover; 
  border: 3px solid #ccc; 
}

@media (max-width: 700px) {
  .button-text {
    display: none;
  }
  
  .button-icon {
    display: inline-block;
  }

  .form-button{
    border-radius: 50px !important;
    background-color: white !important;
    color: black !important;
  }

  .form-button:hover{
    background-color: darkgray !important;
  }
}

.experience-text {
  font-size: 20px;
  align-self: flex-start;
  text-align: left;
  color: white;
}

.description-text {
  font-size: 20px;
  padding: 10px;
  align-self: flex-start;
  margin-bottom: 40px;
  color: rgb(246, 246, 246);
}

.skill-category {
  margin-bottom: 40px;
  padding: 10px;
}

.skill-category-title {
  font-size: 30px;
  color: white;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.skill-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; 
}

.skill-item {
  padding: 20px;
  background: linear-gradient(135deg, #1e1e1e, #2e2e2e); 
  margin: 10px;
  border-radius: 15px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #444;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  text-align: center;
  width: 100px;
  height: 100px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.skill-item:hover {
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); 
  background: linear-gradient(135deg, #2e2e2e, #3e3e3e); 
  animation: rotateAnimation 0.5s ease-in-out;
}

.skill-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 0px;
}

.skill-item strong {
  font-size: 14px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-weight: 500;
  margin-top: 10px;
}

.flex-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 1024px) {
  .avatar {
    width: 100px; 
    height: 100px;
  }

  .about-title, .title {
    font-size: 48px;
  }

  .experience-text, .description-text {
    font-size: 18px;
  }

  .skill-category-title {
    font-size: 26px;
  }

  .skill-item {
    width: 90px;
    height: 90px;
  }

  .skill-icon {
    width: 45px;
    height: 45px;
  }

  .skill-item strong {
    font-size: 13px;
  }

  .form-button {
    font-size: 16px !important;
    padding: 8px 16px !important;
  }
}

@media (max-width: 768px) {
  .avatar {
    width: 80px; 
    height: 80px;
  }

  .about-title, .title {
    font-size: 36px;
  }

  .experience-text, .description-text {
    font-size: 16px;
  }

  .skill-category-title {
    font-size: 24px;
  }

  .skill-item {
    width: 80px;
    height: 80px;
  }

  .skill-icon {
    width: 40px;
    height: 40px;
  }

  .skill-item strong {
    font-size: 12px;
  }

  .form-button {
    font-size: 14px !important;
    padding: 6px 12px !important;
  }

  .flex-row {
    flex-direction: column;
    align-items: center;
  }

  .about-title-container {
    align-items: center;
  }
}

@media (max-width: 480px) {
  .avatar {
    width: 60px; 
    height: 60px;
  }

  .about-container {
    padding: 20px;
  }

  .about-title, .title {
    font-size: 28px;
  }

  .experience-text, .description-text {
    font-size: 14px;
  }

  .skill-category-title {
    font-size: 20px;
  }

  .skill-item {
    width: 70px;
    height: 70px;
  }

  .skill-icon {
    width: 35px;
    height: 35px;
  }

  .skill-item strong {
    font-size: 10px;
  }

  .form-button {
    font-size: 12px !important;
    padding: 4px 8px !important;
    right: -20;
  }

  .flex-row {
    flex-direction: column;
    align-items: center;
  }

  .about-title-container {
    align-items: center;
  }
}
