.blog-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  background-color: rgb(249, 250, 251);
  font-family: "League Spartan";
}

.blog-header {
  text-align: center;
  margin-bottom: 40px;
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-image: url('./../../../public/left-blog.svg'), url('./../../../public/right-blog.svg');
  background-position: 0 0, 100% 0, 0 0;
  background-repeat: no-repeat;
  padding: 40px 20px 20px 20px;
  align-items: center;
  background-color: white;
}

.header-bg {
  max-width: 1200px;
  padding: 20px;
  text-align: left;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
  font-family: "League Spartan";
}

.blog-subtitle {
  font-size: 1.75rem;
  color: rgb(75, 85, 99);
  font-family: "League Spartan";
}

.portfolio-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 120px; 
  height: 120px;
  border-radius: 50%; 
  overflow: hidden;
  object-fit: cover; 
  border: 3px solid #ccc;
  margin-bottom: 5px;
}

.profile-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.cta-button {
  max-height: 50px;
}

.cta-button:hover {
  background-color: #832abf;
}

.featured-posts {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px; 
}

.post-card {
  flex: 0.1 0.9 calc(35% - 10px); 
  margin-bottom: 20px; 
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post-card:hover {
  transform: translateY(-5px);
}

.post-image{
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

img.post-image{
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.post-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.post-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.tags {
  display: flex;
  gap: 10px; 
  align-self: flex-end; 
}

.tag {
  background-color: rgb(242, 230, 254);
  color: rgb(89, 0, 184);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
}
.tag:hover {
  background-color: rgb(222, 191, 253);
  color: rgb(73, 0, 150);
}

.post-title {
  font-size: 2rem;
  color: black;
  margin-bottom: 10px;
}

.post-excerpt {
  font-size: 1.2rem;
  color: #666;
}

.post-meta {
  font-size: 0.9rem;
  color: #999;
  margin-top: 10px;
  align-self: flex-start;
}


@media (max-width: 800px) {
  .blog-header {
      flex-direction: column;
      align-items: center;
  }

  .post-card {
    flex: 0.1 0.9 calc(50% - 10px); 
    min-width: 350px;
  }
  .header-bg {
    text-align: center;
  }
}