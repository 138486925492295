body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-sizing: border-box;
  background: transparent;
  transition: background-color 0.3s ease;
}

.header.scrolled {
  background: rgba(0, 0, 0, 0.9);
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 30px;
  border-radius: 30px;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid #333;
  position: relative;
}

.indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #AE48FF;
  transition: left 0.3s ease, width 0.3s ease;
}

.link {
  color: #bbb;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  position: relative;
  transition: color 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  margin: 0 15px;
  padding: 10px 20px;
  border-bottom: 1px solid transparent;
}

.link .nav-icon {
  margin-right: 8px;
  margin-left: 4px;
  font-size: 20px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.link .nav-text {
  font-weight: bold;
}

.link:hover {
  color: #fff;
}

.link.active {
  color: #fff;
  /* border-bottom: 1px solid #AE48FF; */
}

.link:hover .nav-icon,
.link.active .nav-icon {
  transform: scale(1.2);
  color: #AE48FF;
}

@media (max-width: 1024px) {
  .header {
    padding: 8px 16px;
  }

  .link {
    font-size: 16px;
  }

  .link .nav-icon {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 6px 12px;
  }

  .link {
    font-size: 14px;
  }

  .link .nav-icon {
    font-size: 16px;
  }
}

@media (max-width: 630px) {
  .header {
    padding: 4px 8px;
  }

  .link {
    font-size: 10px;
  }

  .link .nav-icon {
    font-size: 14px;
  }
  .nav{
    width: 500px;
  }
}

@media (max-width: 500px) {
  .link .nav-text {
    display: none; 
    visibility: hidden;
  }

  .link .nav-icon {
    margin-right: 0;
    align-self:  flex-end;
    justify-self: flex-end;
  }

  .link {
    font-size: 10px;
    padding: 4px;
  }
  .nav{
    width: 80%;
    justify-content: space-between;
  }
}

.link:focus {
  outline: 2px dashed #61dafb;
  outline-offset: 4px;
}
