.section {
  position: relative;
  overflow: hidden;
  background-color: black;
  min-height: 100vh;
}

.home-container {
  padding: 80px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  text-align: center;
}

.greeting-container {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-size: 36px;
  margin-bottom: 30px;
  color: #f0f0f0;
  font-family: "League Spartan";
  font-weight: 600;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

.text-container {
  background-color: #000000;
  border: 2px solid #333;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  width: 100%;
  max-width: 800px; 
  text-align: center;
}

.text {
  font-size: 36px;
  line-height: 1.5;
  font-family: "Courier New", Courier, monospace;
  color: #c073ff;
  background-color: #000000;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: left;
}

.terminal-header {
  background-color: #333;
  padding: 5px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.terminal-buttons {
  display: flex;
  gap: 5px;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.terminal-button.close { background-color: #ff5f56; }
.terminal-button.minimize { background-color: #ffbd2e; }
.terminal-button.expand { background-color: #27c93f; }

.button-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  min-width: 350px;
}

.cta-button {
  background-color: #6200ea;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid lightgray;
}

.cta-button:hover {
  background-color: #3700b3;
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.icon-list {
  display: flex;
  justify-content: center;
  color: white;
  padding: 20px;
}

.icon {
  font-size: 60px;
  padding: 5px;
  margin: 0 20px;
  border-radius: 20px;
  border: 1px solid lightgray;
  transition: transform 0.3s, box-shadow 0.3s, color 0.3s;
}

.icon:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px;
  transform: scale(1.1);
}

.linkedin { background: #0077B5; }
.github { background: black; }


@media (max-width: 1540px) {
  .heading {
    font-size: 32px;
  }

  .text {
    font-size: 32px;
  }
}

@media (max-width: 1024px) {
  .heading {
    font-size: 28px;
  }

  .text {
    font-size: 28px;
  }

  .cta-button {
    font-size: 18px;
    padding: 8px 16px;
  }

  .icon {
    font-size: 48px;
    margin: 0 10px;
  }
}

/* Adjust for mobile devices */
@media (max-width: 768px) {
  .home-container {
    padding: 40px 10px 10px;
  }

  .heading {
    font-size: 24px;
  }

  .text {
    font-size: 22px;
  }

  .button-container {
    gap: 10px;
  }

  .cta-button {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 20px;
  }

  .text {
    font-size: 18px;
  }

  .cta-button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .icon {
    /* font-size: 30px; */
    margin: 0 5px;
  }
}

@media (max-width: 400px) {
  .heading {
    font-size: 20px;
  }

  .text {
    font-size: 16px;
  }

  .cta-button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .icon {
    margin: 0 5px;
  }
}