.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    z-index: 3;
  }
  
  .footer-content {
    margin: 5px;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 5px 10px;
    border-radius: 30px;
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #51a2e9; 
  }